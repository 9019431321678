<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
    user: localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : [],
    }
  },
  //   mounted() {
  //   if (this.user) {
  //     this.$store.dispatch(`home/getAdminPermissions`).then((res) => {
  //       let new_user = JSON.parse(JSON.stringify(this.user));
  //       new_user.permissions = res.data;
  //       this.user= new_user;
  //       localStorage.setItem("user", JSON.stringify(new_user));
  //       // this.$forceUpdate();
  //     });
  //   }
  // },
};
</script>
