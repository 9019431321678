let route = '/api/admin/';
let crud = 'recipies';
const actions = {

  getData({ }, queryParams) {
    return new Promise((resolve, reject) => {
      axios.get(`${route}${crud}`, {
        params: queryParams
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  listAll({ }) {
    return new Promise((resolve, reject) => {
      axios.get(`${route}${crud}/list_all`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  

  findData({ }, id) {
    return new Promise((resolve, reject) => {
      axios.get(`${route}${crud}/${id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  saveData({ }, data) {
    return new Promise((resolve, reject) => {
      axios.post(`${route}${crud}`, data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  updateData({ }, data) {
    return new Promise((resolve, reject) => {
      axios.post(`${route}${crud}/${data.id}`, data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  updateStatus({ }, id) {
    return new Promise((resolve, reject) => {
      axios.post(`${route}${crud}/${id}/update_status`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  removeData({ }, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`${route}${crud}/${id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },


  listTool({ }) {
    return new Promise((resolve, reject) => {
      axios.get(`${route}${crud}/list_tools`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  listCuisine({ }) {
    return new Promise((resolve, reject) => {
      axios.get(`${route}${crud}/list_cuisines`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  listIngredient({ }) {
    return new Promise((resolve, reject) => {
      axios.get(`${route}${crud}/list_ingredients`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  listPrimaryIngredient({ }) {
    return new Promise((resolve, reject) => {
      axios.get(`${route}${crud}/list_primary_ingredients`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  listGroup({ }) {
    return new Promise((resolve, reject) => {
      axios.get(`${route}${crud}/list_groups`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },


}
export default {
  namespaced: true,
  actions,
}
