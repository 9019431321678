const actions = {

  prepareCheckout({ }, jsonData) {
    return new Promise((resolve, reject) => {
      axios.post(`/api/customer-api/payment/prepare-checkout`, jsonData)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  


}
export default {
  namespaced: true,
  actions,
}
