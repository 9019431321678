let route = '/api/admin/v1/';
let crud = 'transaction';
const actions = {


    store({ }, data) {
        return new Promise((resolve, reject) => {
            axios.post(`${route}${crud}/store`, data)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    findData({ }, id) {
        return new Promise((resolve, reject) => {
            axios.get(`${route}${crud}/find/${id}`)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    list() {
        return new Promise((resolve, reject) => {
            axios.get(`${route}${crud}/list`)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    updateData({ }, data) {
        let json = JSON.parse(JSON.stringify(data));
        delete json.id;

        return new Promise((resolve, reject) => {
            axios.post(`${route}${crud}/update/${data.id}`, json)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    updateLocationForDelivery({ }, data) {
        let json = JSON.parse(JSON.stringify(data));

        return new Promise((resolve, reject) => {
            axios.post(`${route}${crud}/updateLocationForDelivery/${data.subscription_id}`, json)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },


}
export default {
    namespaced: true,
    actions,
}
