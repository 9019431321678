const actions = {
  verified_data({}, queryParams) {
  
      return new Promise((resolve, reject) => {
        axios.get(`/api/admin/verified_data?date=${queryParams.date}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    check_verified_data({}, queryParams) {
  
      return new Promise((resolve, reject) => {
        axios.get(`/api/admin/check_verified_data?date=${queryParams.date}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    clear_daily_cooking_report({}, queryParams) {
  
      return new Promise((resolve, reject) => {
        axios.get(`/api/admin/clear_daily_cooking_report?date=${queryParams.date}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    validate_daily_cooking_report({}, queryParams) {
  
      return new Promise((resolve, reject) => {
        axios.get(`/api/admin/validate_daily_cooking_report?date=${queryParams.date}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    validate_daily_cooking_report1({}, queryParams) {
  
      return new Promise((resolve, reject) => {
        axios.get(`/api/admin/validate_daily_cooking_report1?date=${queryParams.date}&page=${queryParams.page}&confirm=${queryParams.confirmFinalReport}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    
    second_validate_daily_cooking_report({}, queryParams) {
  
      return new Promise((resolve, reject) => {
        axios.get(`/api/admin/second_validate_daily_cooking_report?date=${queryParams.date}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    get_count_data({}, queryParams) {
  
      return new Promise((resolve, reject) => {
        axios.get(`/api/admin/get_count_data?date=${queryParams.date}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    
  }
  export default {
    namespaced: true,
    actions,
  }
  