const actions = {
  login({}, data) {
    return new Promise((resolve, reject) => {
      //  let form = new FormData();
      //  if( data.username) form.append('email', data.username);
      //  if( data.password) form.append('password', data.password);
      axios.post(`/api/auth/admin/login`, data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  logout({}) {
    return new Promise((resolve, reject) => {
      axios.post(`/api/auth/admin/logout`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  loginDriver({}, data) {
    return new Promise((resolve, reject) => {
      //  let form = new FormData();
      //  if( data.username) form.append('email', data.username);
      //  if( data.password) form.append('password', data.password);

      axios.post(`/api/auth/driver/login`, data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
          reject(error)
        })
    })
  },

  logoutDriver({}) {
    return new Promise((resolve, reject) => {
      axios.post(`/api/auth/driver/logout`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  
  loginCustomer({}, data) {
    return new Promise((resolve, reject) => {
      axios.post(`/api/customer-api/auth/login`, data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  registerCustomer({}, data) {
    return new Promise((resolve, reject) => {
      let config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      axios.post(`/api/customer-api/auth/register`, data,config)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  logoutCustomer({}) {
    return new Promise((resolve, reject) => {
      axios.post(`/api/customer-api/auth/logout`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  resetPassword({}, data) {
    return new Promise((resolve, reject) => {
      axios.post(`/api/customer-api/auth/reset_password`, data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

}
export default {
  namespaced: true,
  actions,
}
