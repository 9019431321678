const actions = {
  get_profile({ }, inputs) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/driver/home/get_profile/${inputs.subscription_id}/${inputs.subscription_detail_id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  update_driver_delivery({ }, data) {
    return new Promise((resolve, reject) => {
      axios.post(`/api/driver/home/update_driver_delivery/${data.subscription_detail_id}`, data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  changePeriod({ }, subscription_detail_id) {
    return new Promise((resolve, reject) => {
      axios.post(`/api/driver/home/change_period_subscription/${subscription_detail_id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  addTrip({ }, data) {
    return new Promise((resolve, reject) => {
      let form = new FormData();
      if(data.date) form.append('date', data.date);
      if(data.period_id) form.append('period_id', data.period_id);
      if(data.temperature) form.append('temperature', data.temperature);
      if(data.temperature_percent) form.append('temperature_percent', data.temperature_percent);
      if(data.count_subscriptions) form.append('count_subscriptions', data.count_subscriptions);
      axios.post(`/api/driver/home/add_trip`, form)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  endTrip({ }, data) {
    return new Promise((resolve, reject) => {
      axios.post(`/api/driver/home/end_trip/${data.current_trip}`, data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  uploadImages({ }, data) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      data.files.forEach((file) => {
        formData.append("files[]", file);
      });
      axios.post(`/api/driver/home/upload_images/${data.id}`, formData)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  uploadLinkMap({ }, data) {
    return new Promise((resolve, reject) => {
      axios.post(`/api/driver/home/upload_link_map/${data.id}`, data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

}
export default {
  namespaced: true,
  actions,
}
