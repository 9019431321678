let route = '/api/admin/';
let crud = 'new_menus';
const actions = {

  getData({ }, queryParams) {
    return new Promise((resolve, reject) => {
      axios.get(`${route}${crud}`, {
        params: queryParams
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  listAll({ }) {
    return new Promise((resolve, reject) => {
      axios.get(`${route}${crud}/list_all`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  

  findData({ }, id) {
    return new Promise((resolve, reject) => {
      axios.get(`${route}${crud}/${id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  saveData({ }, data) {
    return new Promise((resolve, reject) => {
      axios.post(`${route}${crud}`, data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  updateData({ }, data) {
    return new Promise((resolve, reject) => {
      axios.post(`${route}${crud}/${data.id}`, data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  updateStatus({ }, id) {
    return new Promise((resolve, reject) => {
      axios.post(`${route}${crud}/${id}/update_status`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  removeData({ }, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`${route}${crud}/${id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },



  listRecipiesOfGroup({ }, id) { // الوصفة
    return new Promise((resolve, reject) => {
      axios.get(`${route}${crud}/list_recipies_of_group/${id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  listCarbRecipies({ }, id) { // وصفة الكارب
    return new Promise((resolve, reject) => {
      axios.get(`${route}${crud}/list_carb_recipies/${id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  listProteinRecipies({ }, id) { // وصفة البروتين
    return new Promise((resolve, reject) => {
      axios.get(`${route}${crud}/list_protein_recipies/${id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  
  listCuisine({ }) { // المطبخ
    return new Promise((resolve, reject) => {
      axios.get(`${route}recipies/list_cuisines`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },



  listMainIngredients({ }) { // المكون الأساسي
    return new Promise((resolve, reject) => {
      axios.get(`${route}${crud}/list_main_ingredients`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  listGroup({ }) { //الميزة
    return new Promise((resolve, reject) => {
      axios.get(`${route}${crud}/list_groups`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },


}
export default {
  namespaced: true,
  actions,
}
