export default {
    // hostUrl: "http://127.0.0.1:8000",
    // hostUrl: "https://development.v-shape2025.com",
    hostUrl: "https://v-shape2025.com",
    token: localStorage.getItem("token") || null,
    token_driver: localStorage.getItem("token_driver") || null,
    token_customer: localStorage.getItem("token_customer") || null,
    lang: localStorage.getItem("lang") || 'ar',
    permissions: localStorage.getItem("permissions") || [],
}
