// /version.js

const appVersion = '1.2'; // Your updated build version

export function checkAndUpdateVersion() {
  const storedVersion = localStorage.getItem('appVersion');

  if (storedVersion !== appVersion) {
    localStorage.clear();
    localStorage.setItem('appVersion', appVersion);
    return true; // Indicate that the version was updated
  }
  return false; // Indicate that the version is up to date
}
