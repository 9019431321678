const actions = {

  getCustomerData({ }) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/customer-api/home`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getSubscriptionsData({ }) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/customer-api/subscriptions`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getGroupDetails({ }, id) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/customer-api/subscriptions/${id}/group-details`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getGroupDetailsByPackageId({ }, queryParams) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/customer-api/package/${queryParams.package_id}/details`,  {
        params: queryParams
      } )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getPackageList({ }) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/customer-api/package/list`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  findData({ }, id) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/customer-api/get_subscription/${id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  fetchCookingDailyWithDetails( {} , queryParams) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/customer-api/${queryParams.id}/fetch_cooking_daily_with_details`,
        {
          params: queryParams
        } 
      )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  stopSubscribe({ }, data) {
    return new Promise((resolve, reject) => {
      axios.post(`/api/customer-api/subscribe/stop_sub/${data.id}`, data.formData)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  stopCustomerSubscribe({ }, data) {
    return new Promise((resolve, reject) => {
      axios.post(`/api/customer-api/subscriptions/${data.id}/stop`, data.formData)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  startSubscribe({ }, data) {
    return new Promise((resolve, reject) => {
      axios.post(`/api/customer-api/give_sub/${data.id}`, data.formData)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  starCustomerSubscribe({ }, data) {
    return new Promise((resolve, reject) => {
      axios.post(`/api/customer-api/subscriptions/${data.id}/restart`, data.formData)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getListPackageMenu({ }, customer_id) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/customer-api/get_packages/${customer_id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getMasterListPackageMenu({ }) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/customer-api/subscriptions/master-information`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getSettingProfile({ }) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/customer-api/setting/profile`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateSettingProfile({ }, jsonData) {
    return new Promise((resolve, reject) => {
      axios.post(`/api/customer-api/setting/edit-profile`, jsonData)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  
  renewalSubscribe({ }, data) {
    return new Promise((resolve, reject) => {
      axios.post(`/api/customer-api/subscriptions/${data.id}/renewal`, data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getMenu({ }, queryParams) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/customer-api/menu?date=${queryParams.date}&subscription_id=${queryParams.subscription_id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  
  updateMenu({ }, queryParams) {
    return new Promise((resolve, reject) => {
      axios.post(`/api/customer-api/customizes/store`, queryParams)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  


}
export default {
  namespaced: true,
  actions,
}
