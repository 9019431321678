const actions = {
  getData({ }, queryParams) {

    return new Promise((resolve, reject) => {
      axios.get(`/api/admin/home`, {
        params: queryParams
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  listCities({ }) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/driver/home/list_cities`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  listPeriods({ }) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/driver/home/list_periods`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getDetails({ }, inputs) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/driver/home/get_details/${inputs.city_id}/${inputs.period_id}?date=${inputs.date}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getAdminPermissions({ }) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/admin/admins/get_admin_permissions`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },


  getAdminDetails({ }, inputs) {
    let url = `/api/driver/home/report_for_driver_supervisor/${inputs.period_id}?date=${inputs.date}`;
    if (inputs.city_id) {
      url += `&city_id=${inputs.city_id}`;
    }
    if (inputs.trip_status) {
      url += `&trip_status=${inputs.trip_status}`;
    }
    if (inputs.operator) {
      url += `&operator=${inputs.operator}`;
    }
    if (inputs.delivery_all_count_value) {
      url += `&delivery_all_count_value=${inputs.delivery_all_count_value}`;
    }
    if (inputs.not_delivery_count_value) {
      url += `&not_delivery_count_value=${inputs.not_delivery_count_value}`;
    }

    if (inputs.trip_operator) {
      url += `&trip_operator=${inputs.trip_operator}`;
    }

    if (inputs.start_trip_value) {
      url += `&start_trip_value=${inputs.start_trip_value}`;
    }

    if (inputs.end_trip_value) {
      url += `&end_trip_value=${inputs.end_trip_value}`;
    }

    if (inputs.first_time_order_value) {
      url += `&first_time_order_value=${inputs.first_time_order_value}`;
    }

    if (inputs.last_time_order_value) {
      url += `&last_time_order_value=${inputs.last_time_order_value}`;
    }

    if (inputs.format_all_time) {
      url += `&format_all_time=${inputs.format_all_time}`;
    }

    return new Promise((resolve, reject) => {
      axios.get(url)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  
  listTripStatus({ }) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/driver/home/list_trip_status`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },



}
export default {
  namespaced: true,
  actions,
}
