let route = '/api/admin/v1/';
let crud = 'subscription';
const actions = {


    store({}, data) {
        return new Promise((resolve, reject) => {
            axios.post(`${route}${crud}/store`, data, {
                    headers: {
                        'Accept': 'application/json',
                        'Zoho-Authorization': localStorage.getItem('Zoho-Authorization')
                    }
                })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    findData({}, id) {
        return new Promise((resolve, reject) => {
            axios.get(`${route}${crud}/find/${id}`)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    findDeliveryData({}, id) {
        return new Promise((resolve, reject) => {
            axios.get(`${route}${crud}/find_delivery_details/${id}`)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    updateData({}, data) {
        let json = JSON.parse(JSON.stringify(data));
        delete json.id;

        return new Promise((resolve, reject) => {
            axios.post(`${route}${crud}/update/${data.id}`, json)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    updateLocationForDelivery({}, data) {
        let json = JSON.parse(JSON.stringify(data));

        return new Promise((resolve, reject) => {
            axios.post(`${route}${crud}/updateLocationForDelivery/${data.subscription_id}`, json)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    renewalData({}, data) {
        let json = JSON.parse(JSON.stringify(data));
        delete json.id;

        return new Promise((resolve, reject) => {
            axios.post(`${route}${crud}/renewal/${data.id}`, json)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },


}
export default {
    namespaced: true,
    actions,
}