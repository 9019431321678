let route = '/api/admin/v1/';
let crud = 'customers/address';
const actions = {

    listData({ }, id) {
        return new Promise((resolve, reject) => {
            axios.get(`${route}${crud}/list/${id}`)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    updateOrCreateData({ }, data) {
        let json = JSON.parse(JSON.stringify(data));
        delete json.id;

        return new Promise((resolve, reject) => {
            axios.post(`${route}${crud}/create-or-update/${data.id}`, json)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },


}
export default {
    namespaced: true,
    actions,
}
