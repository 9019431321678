let route = '/api/admin/';
let crud = 'subscribe';
const actions = {

  getData({ }, queryParams) {
    return new Promise((resolve, reject) => {
      axios.get(`${route}${crud}/get_subscribe`, {
        params: queryParams
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getDataActive({ }, queryParams) {
    return new Promise((resolve, reject) => {
      axios.get(`${route}${crud}/get_active_subscribe`, {
        params: queryParams
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getDataStop({ }, queryParams) {
    return new Promise((resolve, reject) => {
      axios.get(`${route}${crud}/get_stop_subscribe`, {
        params: queryParams
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getDataExpired({ }, queryParams) {
    return new Promise((resolve, reject) => {
      axios.get(`${route}${crud}/get_expired_subscribe`, {
        params: queryParams
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getDataRemoved({ }, queryParams) {
    return new Promise((resolve, reject) => {
      axios.get(`${route}${crud}/get_cancel_subscribe`, {
        params: queryParams
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },


  listAll({ }) {
    return new Promise((resolve, reject) => {
      axios.get(`${route}${crud}/list_all`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  

  findData({ }, id) {
    return new Promise((resolve, reject) => {
      axios.get(`${route}${crud}/find_subscribe/${id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  saveData({ }, data) {
    if(data.customer.mobile) data.customer.mobile= data.customer.mobile.replace(/ /g,'');
    return new Promise((resolve, reject) => {
      axios.post(`${route}${crud}`, data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  stopSubscribe({ }, data) {
    return new Promise((resolve, reject) => {
      axios.post(`${route}${crud}/stop_sub/${data.id}`, data.formData)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  startSubscribe({ }, data) {
    return new Promise((resolve, reject) => {
      axios.post(`${route}${crud}/give_sub/${data.id}`, data.formData)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  updateData({ }, data) {
    let json = JSON.parse(JSON.stringify(data));
    delete json.id;

    if(json.customer.mobile) json.customer.mobile= json.customer.mobile.replace(/ /g,'');

    return new Promise((resolve, reject) => {
      axios.post(`${route}${crud}/update_subscribe/${data.id}`, json)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  updateStatus({ }, id) {
    return new Promise((resolve, reject) => {
      axios.post(`${route}${crud}/${id}/update_status`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  removeData({ }, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`${route}${crud}/${id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  listBank({ }) {
    return new Promise((resolve, reject) => {
      axios.get(`${route}${crud}/list_bank_names`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  mainIngredientList({ }) {
    return new Promise((resolve, reject) => {
      axios.get(`${route}${crud}/list_first_group_ingredients`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  notMainIngredientList({ }) {
    return new Promise((resolve, reject) => {
      axios.get(`${route}${crud}/list_second_group_ingredients`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  recipeGroupList({ }) {
    return new Promise((resolve, reject) => {
      axios.get(`${route}kitchens/list_recipies_of_groups`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  subCountryList({},id) {
    return new Promise((resolve, reject) => {
      axios.get(`${route}branches/list_sub_countries/${id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  countyList({ }) {
    return new Promise((resolve, reject) => {
      axios.get(`${route}countries/list_special`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  cityList({ }) {
    return new Promise((resolve, reject) => {
      axios.get(`${route}branches/list_cities/1`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  branchList({ }, id) {
    return new Promise((resolve, reject) => {
      axios.get(`${route}branches/list_branches/${id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  companyList({ }) {
    return new Promise((resolve, reject) => {
      axios.get(`${route}${crud}/list_companies`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  groupNameList({ }) {
    return new Promise((resolve, reject) => {
      axios.get(`${route}${crud}/list_group_names`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  packageList({ }) {
    return new Promise((resolve, reject) => {
      axios.get(`${route}packages/list_packages`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  packageDetails({ }, id) {
    return new Promise((resolve, reject) => {
      axios.get(`${route}packages/detail_packages/${id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  renewalSub({ }, data) {
    return new Promise((resolve, reject) => {
      axios.post(`${route}${crud}/renewal_sub`, data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  cancellationReason({ }, data) {
    return new Promise((resolve, reject) => {
      axios.post(`${route}${crud}/cancellation_reason/${data.id}`, data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  calculateRemindDays({ }, id) {
    return new Promise((resolve, reject) => {
      axios.get(`${route}${crud}/calculate_remind_days/${id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  returnSubscription({ }, data) {
    return new Promise((resolve, reject) => {
      axios.post(`${route}${crud}/return_subscription/${data.id}`, data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  addComplaint({ }, data) {
    return new Promise((resolve, reject) => {
      axios.post(`${route}${crud}/complaints`, data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  addCompensation({ }, data) {
    return new Promise((resolve, reject) => {
      axios.post(`${route}${crud}/compensation`, data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  
  stopAllSubscribtion({ }, data) {
    return new Promise((resolve, reject) => {
      axios.post(`${route}${crud}/stop_all_sub`, data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  activateAllSubscribtion({ }, data) {
    return new Promise((resolve, reject) => {
      axios.post(`${route}${crud}/active_all_sub`, data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },


}
export default {
  namespaced: true,
  actions,
}
