const actions = {
  importExcel({ }, data) {
    return new Promise((resolve, reject) => {
      let form = new FormData();
      if (data.file) form.append('file', data.file);
      axios.post(`/api/admin/import_subscribes?date=${data.date}`, form)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
export default {
  namespaced: true,
  actions,
};
