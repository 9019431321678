import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

export default new Vuetify({
  rtl: true,
  theme: {
    dark: false,
      options: {
        customProperties: true,
      },
    themes: {
      light: {
        primary: '#000',
        secondary: '#fafafa',
        third: '#6b7b8c',
        danger: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        fourth: '#fff',
        five: '#2c0781',
        six: '#00f',
      },
    },
  },
});
