let route = '/api/admin/';
let crud = 'admins';
const actions = {

  getData({ }, queryParams) {
    return new Promise((resolve, reject) => {
      axios.get(`${route}${crud}/get_roles`, {
        params: queryParams
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  findData({ }, id) {
    return new Promise((resolve, reject) => {
      axios.get(`${route}${crud}/find_role_with_permissions/${id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  saveData({ }, data) {
    return new Promise((resolve, reject) => {
      axios.post(`${route}${crud}/add_role_with_permissions`, data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  updateData({ }, data) {
    return new Promise((resolve, reject) => {
      axios.post(`${route}${crud}/edit_role_with_permissions/${data.id}`, data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // updateStatus({ }, id) {
  //   return new Promise((resolve, reject) => {
  //     axios.post(`${route}${crud}/${id}/update_status`)
  //       .then((response) => {
  //         resolve(response.data);
  //       })
  //       .catch((error) => {
  //         reject(error)
  //       })
  //   })
  // },

  removeData({ }, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`${route}${crud}/delete_role_with_permissions/${id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

}
export default {
  namespaced: true,
  actions,
}
